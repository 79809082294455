export const EXPLORE = "/explore-all";
export const DIVE_SITES = "/dive-sites";
export const TRIPS = "/trips";
export const DIVE_CENTRES = "/dive-centres";
export const LIVEABOARDS = "/liveaboards";
export const DIVE_RESORTS = "/dive-resorts";
export const DIVE_OPERATORS = "/dive-operators";
export const CONSERVATION_ORGANISATIONS = "/conservation-organisations";
export const SIGN_IN = "/sign-in";
export const SIGN_UP = "/sign-up";
export const PROFILE = "/profile";
export const PROFILE_TRIPS = "/profile/trips";
export const PROFILE_PHOTOS = "/profile/photos";
export const PROFILE_CONNECTIONS = "/profile/connections";
export const TRIP = "/trip";
export const DIVE_SITE = "/dive-site";
export const ORGANISATION = "/organisation";
export const FORGOT_PASSWORD = "/forgot-password";
export const UPDATE_PASSWORD = "/update-password";

export const AUTH_PAGES = [SIGN_IN, SIGN_UP, FORGOT_PASSWORD, UPDATE_PASSWORD];
